import { DataRequestProject } from '../DataRequestProject';
import {
    isCellVisibleByViewFilterCondition,
    isRowCellsAvailableByViewFilter,
    DataTableViewProgressStatus,
} from '../DataTable';
import { TableCellSeenStatus } from '../TableCellSeenStatus';
import {
    ViewColumnSeenStatus,
    RowSeenStatus,
    TableNotificationStatus,
    ViewNotificationStatus,
} from '../TableNotificationStatus';
import { ResetCellInfo } from './data-request-notifications.slice';

export const updateCellsStauts = (
    cellsStatus: TableCellSeenStatus[],
    projects: DataRequestProject[]
) => {
    return cellsStatus;
};

export const mapCellsSeenStatus = (
    projects: DataRequestProject[]
): TableCellSeenStatus[] => {

    return [];
};

export const updateTablesSeenStatus = (
    currentTablesStatus: TableNotificationStatus[],
    projects: DataRequestProject[]
): TableNotificationStatus[] => {
    return currentTablesStatus;
};

export const mapTablesNotifications = (
    cellsSeenStatus: TableCellSeenStatus[],
    projects: DataRequestProject[],
    isAdminView: boolean
) => {
    return [] as any[];
};

export const updateTablesNotifications = (
    cellsSeenStatus: TableCellSeenStatus[],
    tablesStatuses: TableNotificationStatus[],
    isAdminView: boolean
) => {
    return tablesStatuses;
};

export const resetCellsNotifications = (
    cellsSeenStatus: TableCellSeenStatus[],
    cellsToReset: ResetCellInfo[],
    tableId: number,
    viewStatus: ViewNotificationStatus
): TableCellSeenStatus[] => {
    return [];
};

export const resetTableNotifications = (
    tablesNotificationStatus: TableNotificationStatus,
    cellsToReset: ResetCellInfo[],
    viewId: number
): TableNotificationStatus => {
 

    return {
        ...tablesNotificationStatus,
        isNew: false,
    };
};

const initializeTablesStatus = (projects: DataRequestProject[]) => {
    const tablesNotificationsStatuses = projects.flatMap((project) =>
        project.dataTables.map((table): TableNotificationStatus => {
            return {
                projectId: project.id,
                tableId: table.id,
                notificationsCount: 0,
                isNew: table.isNew,
                isNewProject: !project.isSeenByUser,
                viewsNotificationsStatus: table.views.map(
                    (view): ViewNotificationStatus => ({
                        viewId: view.id,
                        notificationsCount: 0,
                        progressStatus: view.progressStatus,
                        columnsSeenStatus: view.viewColumns.map(
                            (viewColumn): ViewColumnSeenStatus => {
                                return {
                                    id: view.id,
                                    customColumnId: viewColumn.customColumnId,
                                    isSeenByAdmin: viewColumn.isSeenByAdmin,
                                    isSeenByUser: view.isSeenByUser
                                        ? viewColumn.isSeenByUser
                                        : true,
                                    isVisible: viewColumn.isVisible,
                                    isFilterEnabled:
                                        viewColumn?.isFilterEnabled ?? false,
                                    filterData: viewColumn?.filterData ?? [],
                                };
                            }
                        ),
                        isSeenByUser: view.isSeenByUser,
                    })
                ),
                rowsSeenStatus: table.rows.map(
                    (row): RowSeenStatus => ({
                        customRowId: row.customRowId,
                        isSeenByAdmin: row.isSeenByAdmin,
                        isSeenByUser: row.isSeenByUser,
                        isVisible: row.isVisible && !row.isRemovalRequested,
                    })
                ),
            };
        })
    );

    return tablesNotificationsStatuses;
};

const getViewCellsNotifications = (
    viewCells: TableCellSeenStatus[],
    rowsStatus: RowSeenStatus[],
    columnsStatus: ViewColumnSeenStatus[],
    isAdmin: boolean
) => {
    const hiddenRowIdsByViewFilter = getHiddenRowIds(viewCells, columnsStatus);

    const cellsWithNotifications = viewCells.filter((viewCell) => {
        const isNewRow = !rowsStatus.some(
            (rowStatus) =>
                rowStatus.customRowId === viewCell.customRowId &&
                getItemSeenStatus(rowStatus, isAdmin)
        );

        const targetViewColumn = columnsStatus.find(
            (columnStatus) =>
                columnStatus.customColumnId === viewCell.customColumnId
        );

        const isNewColumn = !getItemSeenStatus(targetViewColumn, isAdmin);

        const isRowHidden = hiddenRowIdsByViewFilter.some(
            (rowId) => rowId === viewCell.customRowId
        );

        const result =
            !viewCell.isSeenByUser && !isNewRow && !isNewColumn && !isRowHidden;

        return result;
    });

    return cellsWithNotifications?.length ?? 0;
};

const getItemSeenStatus = (
    item: RowSeenStatus | ViewColumnSeenStatus,
    isAdmin: boolean
) => (isAdmin ? item?.isSeenByAdmin : item?.isSeenByUser);

const getNewRowsCount = (
    rowsStatus: RowSeenStatus[],
    isAdmin: boolean,
    viewStatus: ViewNotificationStatus,
    cellsSeenStatus: TableCellSeenStatus[],
    tableId: number
) => {
    let result = 0;

    const tableCells = cellsSeenStatus.filter(
        (cell) => cell.tableId === tableId
    );

    rowsStatus.forEach((rowStatus) => {
        const rowCells = tableCells.filter(
            (f) => f.customRowId === rowStatus.customRowId
        );
        const isAvailable = isRowCellsAvailableByViewFilter(
            rowCells,
            viewStatus.columnsSeenStatus
        );

        if (
            isAvailable &&
            !getItemSeenStatus(rowStatus, isAdmin) &&
            rowStatus.isVisible
        ) {
            result++;
        }
    });

    return result;
};

const getNewColumnsCount = (
    columnsStatus: ViewColumnSeenStatus[],
    isAdmin: boolean
) =>
    columnsStatus.filter((column) => !getItemSeenStatus(column, isAdmin))
        ?.length ?? 0;

const getHiddenRowIds = (
    viewCells: TableCellSeenStatus[],
    columnsStatus: ViewColumnSeenStatus[]
): string[] => {
    const visibleCells = viewCells.filter((viewCell) => {
        const targetViewColumn = columnsStatus.find(
            (columnStatus) =>
                columnStatus.customColumnId === viewCell.customColumnId
        );
        const isVisibleByViewFilter = isCellVisibleByViewFilterCondition(
            viewCell,
            targetViewColumn
        );

        return !isVisibleByViewFilter;
    });

    const result = visibleCells.map((viewCell) => viewCell.customRowId);

    return result;
};
